import { createSlice } from '@reduxjs/toolkit';

export const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        data: {
            tasks:[],
            members:[]
        },
        // singleProject: [],
        loading: false,
        projectAnalysisLoading: false,
        projectAnalysisError: null,
        error: null,
        projectAnalysisData: {},
        mappedResources: {},
        mappedResourcesLoading: false,
        mappedResourcesError: null,
        saveMappedResourcesLoading: false,
        saveMappedResourcesError: null,
        simulationMergeLoading: false,
        simulationMergeError: null,
        taskList:[],
        taskListError:null,
        taskListLoading:false,
        versions:[],
        versionsError:null,
        versionsLoading:false,
        syncWithJiraError:null,
        syncWithJiraLoading:false,
        projectHistory:[],
        projectHistoryError:null,
        projectHistoryLoading:false,
        projectIntegrations:[],
        projectIntegrationsError:null,
        projectIntegrationsLoading:false,
        projectStatsData:{},
        projectStatsError:null,
        projectStatsLoading:false,
        projectShareData:{},
        projectShareError:null,
        projectShareLoading:false,
        singleProjectData:{},
        singleProjectError:null,
        singleProjectLoading:false,
        syncWithAzureError:null,
        syncWithAzureLoading:false,
        notificationSettingLoading:false,
        notificationSettingError:null,
        notificationSettingData:{},
        hasSimulationTasks: false,
    },

    reducers: {
        setProjects: (state: any, action) => {
            const project = action.payload;
            state.data=project;
          },
        setSingleProject: (state: any, action) => {
            const project = action.payload;
            state.singleProject.push(project);
          },
        setProjectsLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setProjectsError: (state: any, action) => {
            state.error = action.payload
        },
        setTasksLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setTasksError: (state: any, action) => {
            state.error = action.payload
        },
        setTask: (state: any, action) => {
            state.task = action.payload
        },
        resetTaskData: (state: any) => {
            state.task = {}
        },
        setTaskLoading: (state: any, action) => {
            state.taskLoading = action.payload
        },
        setTaskError: (state: any,action) => {
            state.taskError = action.payload
        },
        setAddResourcesLoading: (state: any, action) => {
            state.addResourcesLoading = action.payload
        },
        setAddResourcesError: (state: any,action) => {
            state.addResourcesError = action.payload
        },
        setProjectAnalysisLoading: (state: any, action) => {
            state.projectAnalysisLoading= action.payload
        },
        setProjectAnalysisError: (state: any,action) => {
            state.projectAnalysisError = action.payload
        },
        setProjectAnalysisData: (state: any,action) => {
            state.projectAnalysisData = action.payload
        },
        setMappedResourcesLoading: (state: any, action) => {
            state.mappedResourcesLoading= action.payload
        },
        setMappedResourcesError: (state: any,action) => {
            state.mappedResourcesError = action.payload
        },
        setMappedResources: (state: any,action) => {
            state.mappedResources = action.payload
        },
        setSaveMappedResourcesLoading: (state: any, action) => {
            state.saveMappedResourcesLoading= action.payload
        },
        setSaveMappedResourcesError: (state: any,action) => {
            state.saveMappedResourcesError = action.payload
        },
        setSimulationMergeLoading: (state: any, action) => {
            state.simulationMergeLoading= action.payload
        },
        setSimulationMergeError: (state: any,action) => {
            state.simulationMergeError = action.payload
        },
        setTaskList: (state: any,action) => {
            state.taskList = action.payload.tasks
        },
        setTaskListError: (state: any,action) => {
            state.taskListError = action.payload
        },
        setTaskListLoading: (state: any, action) => {
            state.taskListLoading= action.payload
        },
        setVersions: (state: any,action) => {
            state.versions = action.payload.versions
        },
        setVersionsError: (state: any,action) => {
            state.versionsError = action.payload
        },
        setVersionsLoading: (state: any, action) => {
            state.versionsLoading= action.payload
        },
        setSyncWithJiraError: (state: any,action) => {
            state.syncWithJiraError = action.payload
        },
        setSyncWithJiraLoading: (state: any, action) => {
            state.syncWithJiraLoading= action.payload
        },
        setUpdateSimulationError: (state: any,action) => {
            state.updateSimulationError = action.payload
        },
        setUpdateSimulationLoading: (state: any, action) => {
            state.updateSimulationLoading= action.payload
        },
        setProjectHistory: (state: any, action) => {
            state.projectHistory= action?.payload?.history
        },
        setProjectHistoryError: (state: any,action) => {
            state.projectHistoryError = action.payload
        },
        setProjectHistoryLoading: (state: any, action) => {
            state.projectHistoryLoading= action.payload
        },
        setProjectIntegrations: (state: any, action) => {
            state.projectIntegrations= action?.payload?.integrations
        },
        setProjectIntegrationsError: (state: any,action) => {
            state.projectIntegrationsError = action.payload
        },
        setProjectIntegrationsLoading: (state: any, action) => {
            state.projectIntegrationsLoading= action.payload
        },
        setProjectStats: (state: any, action) => {
            state.projectStatsData= action?.payload
        },
        setProjectStatsError: (state: any,action) => {
            state.projectStatsError = action.payload
        },
        setProjectStatsLoading: (state: any, action) => {
            state.projectStatsLoading= action.payload
        },
        setProjectShare: (state: any, action) => {
            state.projectShareData= action?.payload
        },
        setProjectShareError: (state: any,action) => {
            state.projectShareError = action.payload
        },
        setProjectShareLoading: (state: any, action) => {
            state.projectShareLoading= action.payload
        },
        setSingleProjectData: (state: any, action) => {
            state.singleProjectData= action?.payload
        },
        setSingleProjectError: (state: any,action) => {
            state.singleProjectError = action.payload
        },
        setSingleProjectLoading: (state: any, action) => {
            state.singleProjectLoading= action.payload
        },
        setNotificationSettingLoading: (state: any, action) => {
            state.notificationSettingLoading= action.payload
        },
        setNotificationSettingError: (state: any, action) => {
            state.notificationSettingError= action.payload
        },
        setNotificationSettingData: (state: any, action) => {
            state.singleProjectData= action?.payload
        },
        setNotificationSetting: (state: any, action) => {
            state.notificationSettingData= action?.payload
        },
        setHasSimulationTasks: (state: any, action) => {
            state.hasSimulationTasks = action.payload;
        },
    }
});

export const { 
    setProjects, setProjectsLoading, setProjectsError,
    setTasksLoading,setTasksError,setTask,resetTaskData,
    setTaskLoading, setTaskError,setAddResourcesLoading,
    setAddResourcesError,
    setProjectAnalysisLoading,
    setProjectAnalysisError,
    setProjectAnalysisData, 
    setMappedResourcesLoading,
    setMappedResourcesError,
    setMappedResources,
    setSaveMappedResourcesLoading,
    setSaveMappedResourcesError,
    setSimulationMergeLoading,
    setSimulationMergeError,
    setTaskList,setTaskListError,setTaskListLoading,
    setVersions,setVersionsError,setVersionsLoading,
    setSyncWithJiraLoading,setSyncWithJiraError,
    setUpdateSimulationLoading,setUpdateSimulationError,
    setProjectHistoryLoading,setProjectHistoryError,
    setProjectHistory,
    setProjectIntegrationsLoading,setProjectIntegrationsError,
    setProjectIntegrations,
    setProjectStatsLoading,setProjectStats,setProjectStatsError,
    setProjectShareLoading,setProjectShare,setProjectShareError,
    setSingleProjectLoading,setSingleProjectData,setSingleProjectError,
    setNotificationSettingError,setNotificationSettingLoading,
    setNotificationSetting,
    setHasSimulationTasks
} = projectsSlice.actions;

export default projectsSlice.reducer;
