/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import { Dropdown, Sidebar, 
  Avatar,DarkThemeToggle,useThemeMode 
} from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import {
  HiUsers,
  HiOutlineBriefcase,
  HiOutlineViewBoards,
  HiMenuAlt1,
  HiX,
  HiCog,
  HiOutlineBell,
} from "react-icons/hi";
import { useSelector } from 'react-redux'

import { useSideNavContext } from "../../context/SideNavContext";
import { isSmallScreen } from "../../helpers/screens";
import { useJwt } from "../../helpers/common";
import { OrganizationSwitcher, WithUser, useClerk } from '@clerk/clerk-react'
import { useOrganizationList } from "@clerk/clerk-react";
import {useNotificationService} from "../../services/";


export const SideNav: FC = function () {
  const { 
    isOpenOnSmallScreens: isSidebarOpenOnSmallScreens,
    setOpenOnSmallScreens,isPageWithSidebar 
  } =useSideNavContext();
  const { fetchNotifications } = useNotificationService();
  const [currentPage, setCurrentPage] = useState("");
  const [mode, setMode, toggleMode] = useThemeMode();
  const notificationData = useSelector((state: any) => state.notification.data);
  const JWTdata: any = useJwt()
  const {setActive } = useOrganizationList();

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
  }, [setCurrentPage]);

  useEffect(()=>{
    let id:any = "";
      if(JWTdata.org_id){
        id = setTimeout(()=>{
          fetchNotifications(JWTdata.org_id);
        }, 30000 );
        
      }
      return () => clearTimeout(id);
  })
  useEffect(()=>{
      if(JWTdata.org_id){
          fetchNotifications(JWTdata.org_id);
      }
  },[JWTdata.org_id])

  useEffect(() => {
    const t = setTimeout(async (JWT) => {
        const {org_id = "",org_list=[]} = JWT
      if (!org_id ) {
          if(Object.keys(org_list).length){
              const [firstOrgId=''] = Object.keys(org_list)
              if(firstOrgId && typeof setActive == "function"){
                    await setActive({ organization: firstOrgId })
              } else {
                window.location.href = "/signup"
              }
          } else {
            window.location.href = "/signup"
          }
      }
    }, 4000,JWTdata);
    return () => {
        clearTimeout(t)
    }
}, [JWTdata]);

const read_len = notificationData.filter(noti=>!noti.read).length

  return (<>
    <div
      className={classNames("fixed left-0 top-6 z-40", {
        hidden: !isSmallScreen(),
      })}
    >
      <button
        onClick={() => setOpenOnSmallScreens(!isSidebarOpenOnSmallScreens)}
        className="mr-3 cursor-pointer rounded p-2 text-white dark:hover:text-white lg:inline bg-blue-700"
      >
        <HiMenuAlt1 className="h-6 w-6 text-white" />
      </button>
    </div>

    <div
      className={`fixed lg:relative  lg:!block !bg-blue-700 z-50 ${!isSidebarOpenOnSmallScreens ? "w-[295px]" : "w-[65px]"}`}
    >
    </div>
    <div
      className={`fixed lg:!block !bg-blue-700 z-50 ${!isSidebarOpenOnSmallScreens ? "hidden" : ""}`}
    >
      {isSmallScreen() && isSidebarOpenOnSmallScreens && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setOpenOnSmallScreens(false)}
        />
      )}

      <Sidebar
        aria-label="Sidebar with multi-level dropdown"
        collapsed={isSmallScreen() ? !isSidebarOpenOnSmallScreens : isSidebarOpenOnSmallScreens}
        className="border-r-1 border-slate-700 sidebarinner !bg-blue-700 relative z-[9999]"
        theme={{
          root:{
            inner:"h-full overflow-y-auto overflow-x-hidden rounded bg-blue-700 py-4 px-3 !bg-blue-700"
          }
        }}
      >
        <div className="flex flex-col justify-between h-[96vh]">
        <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isSidebarOpenOnSmallScreens)}
                className="mr-0 cursor-pointer rounded p-2 text-white dark:hover:text-white lg:inline bg-blue-700"
              >
                <span className="sr-only">Toggle sidebar</span>
                {isSidebarOpenOnSmallScreens ? (
                  <HiMenuAlt1 className="h-6 w-6 text-white" />
                ) : (
                  <><HiX className="h-6 w-6 text-white" /></> 
                )}
              </button>
            )}
            {!(isSmallScreen() ? !isSidebarOpenOnSmallScreens : isSidebarOpenOnSmallScreens) && (
                <a href="/projects" className="flex items-center">
                    <img src="https://cadence-fe-bucket.s3.us-east-2.amazonaws.com/images/cadencelogo.webp" className="mr-1 h-6 sm:h-9" alt=" Cadencepro Logo" />
                    <div className="text-center">
                        <span className="text-white text-xl font-semibold font-['Inter'] mr-1">Cadencepro</span>
                    </div>
                </a>
            )}
          </div>
            {/* <Sidebar.Items> */}
              <Sidebar.ItemGroup className="!m-0 !p-0 !border-0 h-full flex flex-col justify-center">
                <div className="relative">
                  {read_len ? <span className="w-4 h-4 text-center text-white pt-0.5 text-[8px] absolute top-[-0px] right-0 bg-red-800 shadow-lg rounded-full z-40">{read_len}</span> :""}
                </div>
                <Sidebar.Item
                    href="/notification"
                    icon={HiOutlineBell}
                    label={''}
                    labelcolor={"#eaeaea"}
                    className={
                      "/" === currentPage
                        ? "bg-blue-100 !rounded-full  !text-slate-800 z-99 relative hover:dark:bg-white"
                        : "!rounded-full text-white hover:text-gray-900 relative"
                    }
                    theme={{
                      icon:{
                        base:` text-2xl ${"/" === currentPage ? "bg-gray-100" : 'text-white hover:text-gray-900 '}`
                      }}
                    }
                  >
                  Activity
                  </Sidebar.Item>
                  
                
                <Sidebar.Item
                  href="/strategicmaps"
                  icon={HiOutlineViewBoards}
                  className={
                    "/strategicmaps" === currentPage
                      ? "bg-blue-100 !rounded-full dark:bg-white  !text-slate-800 hover:dark:bg-white group"
                      : "!rounded-full text-white hover:text-gray-900 group"
                  }
                  theme={{
                    icon:{
                      base:` text-2xl ${"/strategicmaps" === currentPage ? "bg-gray-100" : 'text-white group-hover:text-gray-900 dark:group-hover:text-white'}`
                    }}}
                >
                  Strategic maps
                </Sidebar.Item>
                {/* <Sidebar.Item
                  href="/kanban"
                  icon={HiViewGrid}
                  className={
                    "/kanban" === currentPage
                      ? "bg-gray-100"
                      : ""
                  }
                >
                  Kanban
                </Sidebar.Item> */}
                <Sidebar.Item
                  href="/projects"
                  icon={HiOutlineBriefcase}
                  className={
                    "/projects" === currentPage
                      ? "bg-blue-100 !rounded-full dark:bg-white hover:text-white !text-slate-800 group"
                      : "!rounded-full text-white hover:text-gray-900 group"
                  }
                  theme={{
                    icon:{
                      base:` text-2xl ${"/projects" === currentPage ? "hover:text-white" : 'text-white group-hover:text-gray-900 dark:group-hover:text-white'}`
                    }}}
                >
                  Projects
                </Sidebar.Item>
                <Sidebar.Item
                  href="/resources"
                  icon={HiUsers}
                  className={
                    "/resources" === currentPage
                      ? "bg-blue-100 !rounded-full dark:bg-white !text-slate-800 group"
                      : "!rounded-full text-white hover:text-gray-900 group"
                  }
                  theme={{
                    icon:{
                      base:` text-2xl ${"/resources" === currentPage ? "bg-gray-100" : 'text-white group-hover:text-gray-900 dark:group-hover:text-white'}`
                    }}}
                >
                  People
                </Sidebar.Item>
              </Sidebar.ItemGroup>
              
              <div className="mt-auto text-white">
                <Sidebar.ItemGroup className="!m-0 !p-0 !border-0">
                <Sidebar.Item
                    href="#"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      toggleMode();
                      let newMode = mode === "dark" ? "light" : "dark";
                      localStorage.setItem("theme", newMode);
                    }}
                    icon={DarkThemeToggle}
                    className="!rounded-full text-white hover:text-gray-900 group flex items-center"
                    theme={{
                      icon: {
                        base: "text-2xl text-white !outline-none !ring-0 group-hover:text-gray-900 dark:group-hover:text-white !rounded-full hover:!bg-gray-200 dark:hover:!bg-gray-700 w-10 h-10 flex items-center justify-center"
                      },
                      base: "!outline-none !ring-0 focus:!outline-none focus:!ring-0 active:!ring-0 hover:!bg-transparent flex items-center [&>*:first-child]:!mr-[-8px]",
                      content: {
                        base: "px-1 py-2 flex-1 text-left text-sm"
                      }
                    }}
                  >
                    {mode === "dark" ? "Light Mode" : "Dark Mode"}
                  </Sidebar.Item>
                  
                  <Sidebar.Item
                    href="/settings"
                    icon={HiCog}
                    className={
                      "/settings" === currentPage
                        ? "bg-blue-100 !rounded-full dark:bg-white !text-slate-800 group flex items-center justify-start w-full"
                        : "!rounded-full text-white hover:text-gray-900 group flex items-center justify-start w-full"
                    }
                    theme={{
                      icon:{
                        base:"text-blue text-2xl !rounded-full"
                      },
                      content: {
                        base: "px-3 py-2 text-left"
                      }
                    }}
                  >
                    Settings
                  </Sidebar.Item>
                </Sidebar.ItemGroup>
                <div className="block ml-1 mt-2">
                  <UserDropdown setActive={setActive} JWTdata={JWTdata}/>
                </div>
              </div>
          </div>
      </Sidebar>
    </div>
  </>);
};



const UserDropdown: FC = function ({setActive,JWTdata}:any) {
  const { signOut } = useClerk()
  const handleLogoutClick = () => {
    localStorage.removeItem("clerk-db-jwt");
    localStorage.removeItem("workingDays");
    signOut();
    window.location.href = "/";
  }
  const pathname = window.location.pathname
  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get('orgid');
    const {org_list} = JWTdata
    if(orgId){
      setTimeout(()=>{
        if(org_list && org_list[orgId]){
          setActive({ organization: orgId })
        }
        setActive({ organization: orgId })
      },5000)
    }
  },[])
  return (
    <WithUser>
      {(user) => (

        <Dropdown
          arrowIcon={false}
          inline
          label={
            <span>
              <span className="sr-only">User menu</span>
              <Avatar
                alt=""
                img={user.imageUrl}
                rounded
                size="sm"
              />
            </span>
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{user?.fullName || user.username}</span>
            <span className="block truncate text-sm font-medium">
              {user?.primaryEmailAddress?.emailAddress}
            </span>
          </Dropdown.Header>
          <Dropdown.Item>
            <OrganizationSwitcher hidePersonal afterSwitchOrganizationUrl={pathname} />
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogoutClick}>Sign out</Dropdown.Item>
        </Dropdown>
      )}
    </WithUser>
  );
};


const AppDrawerDropdown: FC = function () {
  return null;
};

// export default SideNav;
