import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store';
import { Provider } from 'react-redux';
import App from './App';
import {
  ClerkProvider,
} from "@clerk/clerk-react";
import './index.css';
import { Toaster } from 'react-hot-toast';
import Notifications from 'react-notify-toast';
const ErrorBoundary = React.lazy(() => import('./pages/Error/ErrorBoundary'));

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <ClerkProvider 
            appearance={{
              layout: {
                logoImageUrl: "http://localhost:3000/static/media/cp.efc18886c96d199aea1e.png",
                logoPlacement: "inside",
                showOptionalFields: true,
                socialButtonsPlacement: "bottom",
                socialButtonsVariant: "iconButton",
              }
            }}
            publishableKey={clerkPubKey}
        >
            <App />
          <Toaster position="top-right" />
          <Notifications />
      </ClerkProvider>
    </ErrorBoundary>
    </Provider>
  // </React.StrictMode>
);

